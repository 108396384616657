<template lang="pug">
App(:navigationItems="navigationItems" :cartPrefix="CartPrefix.RESTAURANT" :defaultSaletype="Saletype.TAKEAWAY")
  AgeRestrictionDialog(v-if="isAgeRestrictionEnabled")
  OnboardingDialog(v-else)
</template>

<script setup lang="ts">
import App from "@/App.vue";
import AgeRestrictionDialog from "@/components/modals/AgeRestrictionDialog.vue";
import OnboardingDialog from "@/apps/restaurant/views/OnboardingDialog.vue";
import { Saletype } from "@/models/App";
import { CartPrefix } from "@/models/Cart";
import type { Auth } from "@/plugins/auth";
import { useApp } from "@/store/app";
import { useCart } from "@/store/cart";
import { TypeSnackbar, setSnackbar } from "@/store/layout/snackbar";
import type { NavigationItem } from "@/types";
import { computed } from "vue";
import { inject } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, type RouteLocationRaw } from "vue-router";
import { useRecaptchaProvider } from 'vue-recaptcha/head';
import type { Ref } from "vue";

useRecaptchaProvider();

const { app, activeSaletypes, deliveryService, isAgeRestrictionEnabled } = useApp();
const { cart } = useCart();
const router = useRouter();

const i18n = useI18n();

const canGoToCheckOut = () => {
  if (!cart?.value?.items.length) return false;
  const deliveryMinPurchase =
    cart.value.deliveryPrice?.minPurchase || deliveryService.value?.minPurchase || 0;
  if (cart.value.saletype === Saletype.DELIVERY && cart.value.subTotal < deliveryMinPurchase) {
    return false;
  }
  return true;
};

const auth = inject('auth') as Ref<Auth>;

router.beforeEach((to, from, next) => {
  let redirectRoute: RouteLocationRaw|undefined;
  if (
    cart.value?.saletype === Saletype.DELIVERY &&
    to.name === "cart.checkout" &&
    !canGoToCheckOut()
  ) {
    setSnackbar(i18n.t("cart.snackbar.checkMinPurchase").toString(), TypeSnackbar.WARNING);
    redirectRoute = { name: "cart" };
  } else {
    if (to.meta.auth) {
      if (!auth.value.check()) {
        if (from.name !== "auth.login"){
          redirectRoute = { name: "auth.login", query: { afterLogin: to.name as string } };
        } else redirectRoute ={ name: "order" };
      }
    } 
  }
  redirectRoute ? next(redirectRoute) : next();
});

const navigationItems = computed((): NavigationItem[] => {
  const items: NavigationItem[] = [
    {
      icon: "$locationLight",
      activeIcon: "$locationBold",
      link: { name: "location" },
      name: "app.navigation.location",
    }
  ];
  if (!app.value) return items;

  if (activeSaletypes.value.includes(Saletype.RESERVATION)) {
    items.push({
      icon: "$calendarLight",
      activeIcon: "$calendarBold",
      link: { name: "reservation" },
      name: "app.navigation.reserve"
    });
  }

  if (
    activeSaletypes.value.includes(Saletype.DELIVERY) ||
    activeSaletypes.value.includes(Saletype.TAKEAWAY)
  ) {
    items.push({
      icon: "$orderLight",
      activeIcon: "$orderBold",
      link: { name: "order" },
      name: "app.navigation.order"
    });

    if(cart.value) {
      items.push({
        icon: "$cartLight",
        activeIcon: "$cartBold",
        link: { name: "cart" },
        name: "app.navigation.cart"
      });
    }
  }

  if (activeSaletypes.value.includes(Saletype.OTHER_SERVICES)) {}

  items.push({
    icon: "$peopleLight",
    activeIcon: "$peopleBold",
    link: { name: "user" },
    name: "app.navigation.account"
  });

  return items;
});

</script>
