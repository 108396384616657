<template lang="pug">
div.saletype-section__container.d-flex.flex-column(v-if="cartPrefix === CartPrefix.RESTAURANT")

  div.saletype-section.bg-white.px-5.pt-5.pb-3.rounded-t-xxl

    div.saletype-switch
      h1.text-h5.font-weight-medium.py-3 {{ $t("order.saletypeSection.pickUpMethod") }}
      EatBtnGroup.my-3(:modelValue="selectedSaletype" @update:modelValue="onSaletypeSelectorChange" :options="selectableSaletypes")

    div.selectors(v-if="cart")

      div.time-slot-selector__container.pt-3.pb-5(v-if="cart.orderTimeSlot")
        h6.text-h6.pb-2 {{ $t("order.saletypeSection.date") }}
        DateTimeSlotButton(:modelValue="cart.orderTimeSlot" @update:modelValue="onUpdateDateTimeSlot" :errored="!isSlotValid" )
        EatFieldMessage(:errorMessage="isSlotValid ? '' : 'form.errors.dateTimeSlot.invalid'")

      div.address-selector__container
        div(v-if="selectedSaletype === Saletype.TAKEAWAY").text-h6.pb-2 {{ $t("order.saletypeSection.takeawayAddress") }}
        div(v-if="selectedSaletype === Saletype.DELIVERY").text-h6.pb-2 {{ $t("order.saletypeSection.deliveryAddress") }}
        transition(name="fade" mode="out-in")
          a.location-address__container.d-flex.align-center.border.rounded-sm.py-2.px-4.bg-primary(v-if="selectedSaletype === Saletype.TAKEAWAY" key="1" :href="gmapsLink" target="_blank")
            div.me-auto.py-2(v-if="location")
              span {{ $t("order.saletypeSection.takeawayMessagge") }}
              span.font-weight-medium {{ ` ${location.address}, ${location.city}` }}
            EatIcon.flex-shrink-0(icon="$directions") 

          div.delivery-address__container.border.rounded-sm.py-2.px-4.d-flex.align-center(v-else-if="selectedSaletype === Saletype.DELIVERY" key="2")
            router-link.delivery-address__btn.d-flex.align-center(v-if="cart.deliveryAddress" :to="{ name: 'order.deliveryAddress' }")
              EatIcon(icon="$point").text-primary
              AddressFormatted.px-3(:address="cart.deliveryAddress")
              EatIcon.fake-drop-down__btn(icon="$chevronDown" :size="18")
            router-link.delivery-address__btn.text-primary.d-flex.align-center(v-else :to="{ name: 'order.deliveryAddress' }")
              EatIcon(icon="$pointPlus").text-primary
              span.px-3 {{ $t("order.saletypeSection.addDeliveryAddress") }}
              EatIcon.fake-drop-down__btn(icon="$chevronDown" :size="18")

</template>

<script setup lang="ts">
import DateTimeSlotButton from "@/components/dateTimeSlots/DateTimeSlotButton.vue";
import EatBtnGroup from "@/components/buttons/EatBtnGroup.vue";
import { Saletype } from "@/models/App";
import { shortAddress } from "@/models/AppLocation";
import { useApp } from "@/store/app";
import { computed, onMounted, ref, watch } from "vue";
import { useOrderSaletype } from "@/store/order/saletype";
import { CartPrefix } from "@/models/Cart";
import { useCart } from "@/store/cart";
import { type DateTimeSlot } from "@/store/timeSlots/dateTimeSlots";
import { useTimeSlots } from "@/store/timeSlots/timeSlots";
import type { SelectData } from "@/components/inputs/EatSelect.vue";

const { cart, cartPrefix, setCartOrderTimeSlot } = useCart();
const { location, activeSaletypes, deliveryService } = useApp();
const { selectedSaletype, onSaletypeChange } = useOrderSaletype();
const { isStillAvailable, availableSlots } = useTimeSlots();
const { getOrderSlots } = useTimeSlots();

const isSlotValid = computed(() => {
  return cart.value?.orderTimeSlot?.timeSlot
    ? isStillAvailable(cart.value.orderTimeSlot.timeSlot, availableSlots.value)
    : false;
})

onMounted(async () => {
  if (cart.value?.orderTimeSlot?.date)
    availableSlots.value = await getOrderSlots(cart.value.orderTimeSlot.date);
});

const gmapsLink = computed(() => {
  if (!location.value) return "";
  return `https://www.google.com/maps/search/?api=1&query=${
    location.value.businessName
  },${shortAddress(location.value)}`;
});

interface SaletypeSelectData extends SelectData { value: Saletype; };
const selectableSaletypes = computed(() => {
  return activeSaletypes.value.reduce(
    (options: SaletypeSelectData[], saletype: Saletype, i): SaletypeSelectData[] => {
      if ([Saletype.TAKEAWAY, Saletype.DELIVERY].includes(saletype)) {
        options.push({
          value: saletype,
          label: `general.saletype.${saletype}`
        });
      }
      return options;
    },
    []
  );
});

const onSaletypeSelectorChange = async (saletype: Saletype) => {
  if (saletype === Saletype.DELIVERY && deliveryService.value?.externalUrl) {
    window.location.replace(deliveryService.value.externalUrl);
  } else {
    await onSaletypeChange(saletype);
  }
};

const onUpdateDateTimeSlot = (dateTimeSlot: DateTimeSlot) => {
  if (dateTimeSlot.date) setCartOrderTimeSlot(dateTimeSlot);
};
</script>

<style lang="scss" scoped>
.saletype-section {
  position: relative;
  height: var(--saletype-section-height, auto);
  max-height: var(--saletype-section-height, auto);
}

.saletype-switch__container {
  background-color: var(--gcontrols);
  width: 100%;
  .saletype-switch__btn {
    position: relative;
    min-height: 48px;
    overflow: hidden;
    .text {
      position: relative;
      transition: color 0.3s ease-in-out;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--active {
      &:first-of-type > .active-background,
      &:last-of-type > .active-background {
        transform: translateX(0);
      }
    }
  }
}

.location-address__container {
  text-decoration: none;
}

.fake-drop-down__btn {
  text-decoration: none;
  margin-left: auto;
  display: block;
}

.btn--google-maps {
  min-width: 121px;
}

.delivery-address__selected {
  text-decoration: none;
}

.delivery-address__container,
.location-address__container {
  min-height: 80px;
}

.delivery-address__btn {
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
