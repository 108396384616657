<template lang="pug">
div.screen.d-flex.flex-column
  div.top
    EatImage(:src="image" :aspectRatio="appHeight < 700 ? '16/9' : '1/1'")
    EatImage.logo.rounded(:src="logo" aspectRatio="1/1" v-if="logo")
  div.bottom.d-flex.flex-column.align-center.bg-white
    div.text-black.text-h4.text-center.mb-5 {{ title }}
    div.text-black.text-h6.font-weight-regular.text-center {{ text }}
</template>

<script setup lang="ts">
import { useAppHeight } from "@/store/layout/layout";

defineProps({
  logo: {
    type: String,
    required: false
  },
  image: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  }
});

const appHeight = useAppHeight();
</script>

<style lang="scss" scoped>
.screen {
  position: relative;
  .top {
    position: relative;
    .logo {
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid #ffffff;
      width: 108px;
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(var(--app-height) - calc(var(--app-width) * 9 / 16));
    @media screen and (min-height: 700px) {
      height: calc(var(--app-height) - var(--app-width));
    }
    padding: 32px 27px;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }
}
</style>
