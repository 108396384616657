<template lang="pug">
FeedbackView(@close-page="onClosePage" backgroundClass="bg-success" :image="image")
  template(v-slot:first-container)
    div.text-h6.mb-6 {{ $t('reservation.reservationSuccess.sent') }}
    div.mb-6 {{ confirmMessage ? confirmMessage : $t('reservation.reservationSuccess.confirmation') }}
      
    div.reservation-time-slot.border.rounded-sm.my-5
      div.date-time-slot__container.d-flex.bg-white.text-black.rounded-sm.py-2.px-4
          div.d-flex.align-center.flex-grow-1
            EatIcon(icon="$calendar").text-primary
            div.px-4.flex-grow-1.text-start
                div.pickup-date.font-weight-medium {{ date }}
                div.text-body-2 {{ time }}

    eat-btn.mt-10.mb-4.bg-primary.w-100(@click="addToCalendar()" :loading="isDownloadingIcs") {{ $t("reservation.reservationSuccess.saveToCalendar") }}
    
    div.text-center
        div(v-if="isDeviceIOS")
            div {{ $t('general.or') }}
            div.d-flex.flex-column.align-center.justify-center.mt-4.mb-4
              ApplePassBtn(:isDownloading="isDownloadingPkPass" @click="addToApplePay")
            div.open-in-safari__message.text-body-2.mb-2(v-if="!isDeviceIOSAndBrowserSafari") {{ $t('reservation.pleaseOpenInSafari') }}

</template>

<script setup lang="ts">
import FeedbackView from "@/layout/FeedbackView.vue";
import ApplePassBtn from "@/components/buttons/ApplePassBtn.vue";
import { onMounted, type Ref, ref } from "vue";
import { downloadReservationPkPass, downloadIcs } from "@/utils/downloadFile";
import { isDeviceIOS, isDeviceIOSAndBrowserSafari } from "@/utils/userAgentData";
import { getReservation as getPKpass } from "@/api/AppleWalletPass";
import { getIcs } from "@/api/CalendarEvent";
import { useReservation } from "@/store/reservation";
import type { NullRef } from "@/utils";
import { useApp } from "@/store/app";
import { setAppTitle } from "@/store/appTitle";
import { goBack } from "@/store/routerHistory";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const image = new URL('@/assets/images/booking.jpg', import.meta.url).toString();

const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const { reservation } = useReservation();
const date = reservation.value?.date;
const time = reservation.value?.time;
const { app } = useApp();
const confirmMessage: NullRef<string> = ref(null);
confirmMessage.value = app.value?.services.reservation.orderConfirmedMessage;

setAppTitle(i18n.t("reservation.reservationSuccess.appTitle").toString());

const isDownloadingPkPass: Ref<boolean> = ref(false);

onMounted(() => {
  if (!reservation.value) {
    goBack(router, route);
  }
});

async function addToApplePay() {
  if (isDeviceIOSAndBrowserSafari) {
    isDownloadingPkPass.value = true;
    try {
      const pkpass = await getPKpass(reservation.value?.reservationId as string);
      downloadReservationPkPass(pkpass);
    } catch {
      throw new Error(i18n.t("app.snackbar.errorDownloadFile") as string);
    } finally {
      isDownloadingPkPass.value = false;
    }
  }
}

const isDownloadingIcs: Ref<boolean> = ref(false);

async function addToCalendar() {
  isDownloadingIcs.value = true;
  try {
    const ics = await getIcs(reservation.value?.reservationId as string);
    downloadIcs(ics);
  } catch {
    throw new Error(i18n.t("app.snackbar.errorDownloadFile") as string);
  } finally {
    isDownloadingIcs.value = false;
  }
}

const onClosePage = () => {
  router.replace({ name: "reservation" });
};
</script>

<style lang="scss" scoped>
.reservation-time-slot {
  width: 100%;
}

.date-time-slot__container {
  min-height: 80px;
  width: 100%;
}

.pickup-date {
  text-transform: capitalize;
}

.date-time-picker__saletype {
  text-transform: capitalize;
}

.open-in-safari__message {
  max-width: 25ch;
  text-align: center;
  line-height: 1.2rem;
}
</style>
