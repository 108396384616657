import type { NullRef } from "@/utils";
import { ref } from "vue";

export interface Reservation {
  date: string;
  time: string;
  reservationId: string;
}

const reservation: NullRef<Reservation> = ref(null);

export function useReservation() {
  return {
    reservation
  };
}
