<template lang="pug">
FullPageDialog(v-model="showOnboarding" transition="shrink-on-leave" teleportTarget="#onboarding-dialog")
  template(v-slot:header)
    div.onboarding-header.d-flex.justify-space-between.flex-grow-1.align-center.px-6.py-3
      button.text-primary.text-body-1(icon @click="showOnboarding = false") {{ $t("onboarding.skip") }}
      button.px-7.py-1.rounded-sm.bg-primary(@click="goToLogin") {{ $t("onboarding.signIn") }}
  template(v-slot:main)  
    div.carousel-container.d-flex.flex-grow-1(v-scrollable="true" id="carousel")
      div.carousel-item.flex-shrink-0.d-flex.flex.column(v-for="item, i in carouselItems" :key="i" :id="`carousel-item-${i}`")
        OnboardingScreen.flex-grow-1(:logo="item.logo" :image="item.image" :title="$t(item.title)" :text="$t(item.text)")
    div.custom-delimiters
      EatIcon.custom-delimiter-icon.text-black(v-for="item, i in carouselItems" :key="i" :id="i" icon="$dot" :size="10" :class="{active: i === activeItem}")
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, nextTick } from "vue";
import OnboardingScreen from "@/apps/restaurant/views/OnboardingScreen.vue";
import FullPageDialog from "@/layout/partials/FullPageDialog.vue";
import { useApp } from "@/store/app";
import { LS, LSKeys } from "@/utils/localStorage";
import { isStandalone } from "@/utils/userAgentData";
import { useRouter } from "vue-router";

interface CarouselItem {
  logo?: string;
  image: string;
  title: string;
  text: string;
}

const { app, location } = useApp();
const router = useRouter();

const activeItem = ref(0);
const showOnboarding = ref(false);

const carouselItems = ref<CarouselItem[]>([
  {
    logo: location.value?.logoUrl || "",
    image: app.value?.coverImageUrl || "",
    title: "onboarding.firstScreen.title",
    text: "onboarding.firstScreen.text"
  },
  {
    image: app.value?.coverImageUrl || "",
    title: "onboarding.secondScreen.title",
    text: "onboarding.secondScreen.text"
  },
  {
    image: app.value?.coverImageUrl || "",
    title: "onboarding.thirdScreen.title",
    text: "onboarding.thirdScreen.text"
  },
  {
    image: app.value?.coverImageUrl || "",
    title: "onboarding.fourthScreen.title",
    text: "onboarding.fourthScreen.text"
  }
]);

onMounted(() => {
  showOnboarding.value = (!LS.get(LSKeys.ONBOARDING) as boolean) && isStandalone;
  nextTick(() => {
    nextTick(() => {
      const intersectionObserver = new IntersectionObserver(
        entries => {
          const entry = entries[0];
          if (entry.intersectionRatio >= 1) {
            activeItem.value = parseInt(entry.target.id?.replace("carousel-item-", ""));
          }
        },
        { root: document.getElementById("carousel"), threshold: 1.0 }
      );
      for (const i in carouselItems.value) {
        const element = document.getElementById(`carousel-item-${i}`);
        if (element) intersectionObserver.observe(element);
      }
    });
  });
});

onUnmounted(() => {
  LS.set(LSKeys.ONBOARDING, true);
});

const goToLogin = () => {
  showOnboarding.value = false;
  if (router.currentRoute.value.name !== "auth.login") {
    router.push({ name: "auth.login" });
  }
};
</script>

<style lang="scss" scoped>
.carousel-container {
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
  .carousel-item {
    width: 100%;
    scroll-snap-align: start;
  }
}

.custom-delimiters {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  gap: 10px;
  .custom-delimiter-icon {
    opacity: 0.2;
    transition: opacity 0.3s;
    font-size: 8px;
    &.active {
      opacity: 1;
    }
  }
}
</style>
