<template lang="pug">
EatView
  template(v-slot:default)
    div.flex-grow-1.d-flex.flex-column(v-if="app && location" v-scrollable="true")
      div.cover-image-background.rounded-t-xxl.bg-white
        div.cover-image__container.rounded-t-xxl.overflow-hidden.bg-white
          a.btn-back-multilocation.bg-primary.rounded-lg.d-flex.align-center.py-2.ps-2.pe-4(@click="showMoreDialog = true" :href="multilocationUrl" v-if="multilocationUrl")
            EatIcon(icon="$back" :size="18")
            div.ps-2.font-weight-medium {{ $t("location.action.multilocationButton") }}
          EatImage.cover-image(:src="app.coverImageUrl" aspectRatio="16/9")
        div.logo__container.rounded-lg
          EatImage.logo.rounded-lg(:src="location.logoUrl")

      div.bg-neutral-a.d-flex.flex-column.flex-grow-1
        div.container.rounded-b-xxl.pb-3.bg-white.mb-1
          div.location-info
            h1.text-h5.font-weight-medium {{ location.businessName }}
            div.subtitle-2.mt-2 {{ fullAddress(location) }}
            div.text-primary.subtitle-2.pb-3 {{ isLocationOpenToday ? $t("location.open") + " " + todaysOpeningHours : $t("location.closed") }}
          div(v-if="location.description")
            div.description__title.text-h6.mt-5.py-4 {{ $t("location.description")}}
            div.description__body.my-3
              div(v-html="location.description")

        RoundedContainer.mb-1(v-if="location.phone || location.whatsapp || location.email || location.facebook || location.instagram")
          div.contacts.text-h6.py-4(v-if="location.phone || location.whatsapp || location.email") {{ $t('location.contacts') }}
          div.mt-4(v-if="location.phone || location.whatsapp || location.email")
            a.border.rounded-lg.py-3.px-4.mb-4.d-flex.align-center(depressed active-class="bgselected" :href="`tel:${location.phone}`" v-if="location.phone")
              EatIcon(icon="$phone")
              div.ml-4.text-body-1.mr-auto {{ $t('location.action.phone') }}
              div.text-caption {{ $t('call') }}
            a.border.rounded-lg.py-3.px-4.mb-4.d-flex.align-center(depressed active-class="bgselected" :href="location.whatsapp" v-if="location.whatsapp")
              EatIcon(icon="$whatsapp")
              div.ml-4.text-body-1.mr-auto {{ $t('location.action.whatsapp') }}
            a.border.rounded-lg.py-3.px-4.mb-4.d-flex.align-center(depressed active-class="bgselected" :href="`mailto:${location.email}`" v-if="location.email")
              EatIcon(icon="$mail") 
              div.ml-4.text-body-1.mr-auto {{ $t('location.action.email') }}

          div.follow-us.text-h6.py-4(v-if="location.facebook || location.instagram") {{ $t('location.followUs') }}
          div.mt-4(v-if="location.facebook || location.instagram")
            button.border.rounded-lg.py-3.mb-4.px-4.d-flex.align-center(:href="location.facebook" v-if="location.facebook")
              EatIcon(icon="$facebook")
              div.ml-3 {{ $t('location.action.facebook') }}
            button.border.rounded-lg.py-3.px-4.mb-4.d-flex.align-center(:href="location.instagram" v-if="location.instagram")
              EatIcon(icon="$instagram")
              div.ml-3 {{ $t('location.action.instagram') }}

        div.rounded-xxl.py-3.px-5.mb-1.bg-white.flex-grow-1.d-flex.flex-column(v-if="openingHours && openingHours.length")
          div.opening-hours.text-h6.py-4 {{ $t("location.openingHours") }}
          div.my-4
            button.w-100.d-flex.align-center.gap-3.cursor-pointer.py-3.px-4.border(
              @click="showOpeningHoursDropDown = !showOpeningHoursDropDown"
              :class="{ 'bg-selected': showOpeningHoursDropDown }"
            )
              EatIcon(icon="$clock")
              div.text-left.flex-grow-1.text-wrap
                span.pr-2 {{ isLocationOpenToday ? $t("location.open") : $t("location.closed") }} 
                span.flex-shrink-0 {{ todaysOpeningHours }}
              EatIcon(:icon="showOpeningHoursDropDown ? '$chevronUp' : '$chevronDown'")
            EatUl.border-l.border-b.border-r(v-if="showOpeningHoursDropDown")
              EatLi.d-flex.gap-3(v-for="day,i in openingHours" :key="i")
                div.text-primary.text-capitalize(style="width: 56px") {{ weekDaysLabels[i] }}
                div {{ day || $t("location.closed") }}

          div.services.text-h6.py-4(v-if="services && services.length") {{ $t("location.services") }}
          div.service-tags__container.d-flex.mt-3.mb-5
            div.service-tag.border.rounded-xl.d-flex.align-center.px-4.py-3.mb-2.me-2(v-for="service, i in services" :key="i")
              EatIcon(:icon="service.icon").text-primary
              div.pl-3.text-primary.font-weight-medium {{ service.name }}
          div.pa-5.text-center.mt-auto
            a.text-decoration-underline.ml-1(:href="app.urlPrivacyPolicy" target="_self" @click.stop) {{ $t('order.privacyPolicyLink') }}
            span {{ $t("general.and") }}
            a.text-decoration-underline.ml-1(:href="app.urlTermsOfSales" @click.stop) {{ $t('order.termsOfSalesLink') }}
            div.text--disabled(v-if="app.companyVAT") {{ $t("general.VAT") + ": " + app.companyVAT }}
      
          //-- .mx-n3(v-if="location.banner")
            EatImage(
              :src="location.banner.image",
              contain,
              width="100%",
              :href="location.banner.link",
              target="_blank"
            )

        div.map__container
          a.text-decoration-none(:href="gmapsLink" target="_blank")
            EatImage.rounded-t-xxl(:src="staticMapImage" aspectRatio="16/9")
            div.map__button.pa-4.d-flex.flex-column.fill-height.align-end.justify-end
              EatFloatBtn.elevation-2(
                :href="gmapsLink",
                target="_blank"
                icon="$directions"
              ) {{ $t("location.action.directions") }}
</template>

<script setup lang="ts">
import { fullAddress, shortAddress } from "@/models/AppLocation";
import { useApp } from "@/store/app";
import { setAppTitle } from "@/store/appTitle";
import { computed, onMounted, ref } from "vue";
import useOpeningHours from "@/store/openingHours";
import { Saletype } from "@/models/App";
import config from "@/config";
import { useShowMoreDialog } from "@/store/layout/moreDialog";
import { useI18n } from "vue-i18n";
import { useCalendar } from "@/store/timeSlots/calendar";

interface ServiceTag {
  name: string;
  icon: string;
}

const i18n = useI18n();
const { app, location, activeSaletypes } = useApp();
const showMoreDialog = useShowMoreDialog();
setAppTitle(location.value?.businessName);

const multilocationUrl = ref("");
const showOpeningHoursDropDown = ref(false);
const services = ref<ServiceTag[]>([]);

const { weekDaysLabels } = useCalendar();
const {
  isLocationOpenToday,
  todaysOpeningHours,
  openingHours,
} = useOpeningHours(location.value?.openingHours);

onMounted(() => {
  multilocationUrl.value = app.value?.appMasterUrl
    ? app.value.appMasterUrl + "/multilocation"
    : "";

  services.value = activeSaletypes.value.reduce(
    (services: ServiceTag[], saletype): ServiceTag[] => {
      if (saletype === Saletype.RESERVATION) {
        services.push({
          name: i18n.t(`general.saletype.${Saletype.RESERVATION}`).toString(),
          icon: "$people"
        });
      } else if (saletype === Saletype.DELIVERY) {
        services.push({
          name: i18n.t(`general.saletype.${Saletype.DELIVERY}`).toString(),
          icon: "$point"
        });
      } else if (saletype === Saletype.TAKEAWAY) {
        services.push({
          name: i18n.t(`general.saletype.${Saletype.TAKEAWAY}`).toString(),
          icon: "$people"
        });
      }
      return services;
    },
    []
  );
});

const staticMapImage = computed(() => {
  if (!location.value) return "";
  const parameters = new window.URLSearchParams({
    scale: "2",
    size: "720x405",
    markers: shortAddress(location.value)
  });
  return `https://maps.googleapis.com/maps/api/staticmap?${parameters.toString()}&key=${
    config.mapsAPIKey
  }&style=feature:poi.business|visibility:off`;
});

const gmapsLink = computed(() => {
  if (!location.value) return "";
  return `https://www.google.com/maps/search/?api=1&query=${
    location.value.businessName
  },${shortAddress(location.value)}`;
});
</script>

<style lang="scss" scoped>

$logoHeight: 112px;

.cover-image-background {
  position: relative;
  .cover-image__container {
    padding-bottom: 60px;
    .cover-image {
      width: 100%;
    }
    .btn-back-multilocation {
      position: absolute;
      left: 16px;
      top: 16px;
    }
  }
  .logo__container {
    width: $logoHeight;
    position: absolute;
    bottom: 0;
    left: 20px;
    background: rgb(197, 245, 49);
    background: linear-gradient(226deg, rgba(197, 245, 49, 1) 10%, rgba(0, 113, 242, 1) 92%);
    .logo {
      border: 1px solid transparent;
    }
  }
}

.service-tags__container {
  flex-wrap: wrap;
  .service-tag {
    min-width: 140px;
  }
}

.map__container {
  position: relative;
  .map__button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
