<template lang="pug">
div.saletype-summary__container.sticky-container.border-b.pb-1.bg-neutral-a
  div.saletype-summary__placeholder.h-100.bg-white.border.border-clr-white
  div.saletype-summary.d-flex.align-center.px-4.bg-primary(v-if="selectableSaletypes.length" ref="$summary")
    div.saletype-summary__text.flex-grow-1.pe-2
      div.saletype-summary__slot.text-capitalize {{ pickupDate + (pickupTime ? " | " + pickupTime : "") }}
      div.saletype-summary__address.font-weight-medium(v-if="selectedSaletype === Saletype.DELIVERY && deliveryAddress") {{ deliveryAddress }}
      div.saletype-summary__address.font-weight-medium(v-else-if="selectedSaletype === Saletype.TAKEAWAY && location?.address") {{ `${location.address}, ${location.city}` }}
    div.ms-auto.saletype-summary__drop-down
      EatSelect(small :options="selectableSaletypes" :modelValue="selectedSaletype" @update:modelValue="onSaletypeSelectorChange")
</template>

<script setup lang="ts">
import type { SelectData } from "@/components/inputs/EatSelect.vue";
import { addressToString } from "@/models/Address";
import { Saletype } from "@/models/App";
import { useApp } from "@/store/app";
import { useCart } from "@/store/cart";
import { useOrderSaletype } from "@/store/order/saletype";
import { computed, onMounted, ref } from "vue";
import { DateTime } from "luxon";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useI18n } from "vue-i18n";

const { cart } = useCart();
const { deliveryService, activeSaletypes, location } = useApp();
const i18n = useI18n();

const { selectedSaletype, onSaletypeChange } = useOrderSaletype();

const deliveryAddress = computed(() => {
  return cart.value?.deliveryAddress ? addressToString(cart.value?.deliveryAddress) : null;
});

const selectableSaletypes = computed(() => {
  return activeSaletypes.value.reduce(
    (options: SelectData[], saletype: Saletype, i): SelectData[] => {
      if ([Saletype.TAKEAWAY, Saletype.DELIVERY].includes(saletype)) {
        options.push({
          value: saletype,
          label: `general.saletype.${saletype}`
        });
      }
      return options;
    },
    []
  );
});

const onSaletypeSelectorChange = async (saletype: Saletype) => {
  if (saletype === Saletype.DELIVERY && deliveryService.value?.externalUrl) {
    window.location.replace(deliveryService.value.externalUrl);
  } else {
    await onSaletypeChange(saletype);
  }
};

const pickupDate = computed(() => {
  if (cart.value?.orderTimeSlot?.date) {
    const { day, month, year } = cart.value.orderTimeSlot.date;
    return DateTime.fromObject({ day, month, year }).toFormat("cccc d LLLL", {
      locale: i18n.locale.value
    });
  }
});
const pickupTime = computed(() => {
  if (cart.value?.orderTimeSlot?.timeSlot) {
    return cart.value?.orderTimeSlot?.timeSlot.time;
  }
  return null;
});

const $summary = ref<HTMLElement>();
const scroller = ref<HTMLElement | null>(null);
const trigger = ref<HTMLElement | null>(null);
const endScrollTrigger = ref(0);

const setupAnimation = () => {
  const timeline = gsap
    .timeline({ paused: true })
    .to($summary.value as Element, { opacity: 1 });

  scroller.value = document.querySelector("[id$=order-scrollable");
  trigger.value = document.querySelector("[id$=order-scroll-trigger]");
  endScrollTrigger.value = trigger.value?.getBoundingClientRect().bottom || 0;

  ScrollTrigger.create({
    scroller: scroller.value,
    trigger: trigger.value,
    start: () => `70% top`,
    end: `${endScrollTrigger.value}px top`,
    fastScrollEnd: true,
    animation: timeline,
    scrub: 0.1,
    invalidateOnRefresh: true
  });
};

onMounted(() => {
  setupAnimation();
});
</script>

<style lang="scss">
:root {
  --saletype-summary-height: 64px;
}
</style>

<style lang="scss" scoped>
.sticky-container {
  position: sticky;
  top: -1px;
  left: 0;
  right: 0;
  z-index: 2;
  will-change: transform;
}

.saletype-summary__container {
  height: var(--saletype-summary-height);
  min-height: var(--saletype-summary-height);
  z-index: 3;
}

.saletype-summary {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  .saletype-summary__text {
    width: calc(100% - 140px);
    .saletype-summary__slot {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .saletype-summary__address {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .saletype-summary__drop-down {
    min-width: 140px;
    position: relative;
    z-index: 1;
  }
}
</style>
