<template lang="pug">
EatView.bg-neutral-a(:loading="viewLoading" :isScrollable="true" @submit="makeReservation" ref="$form")

  template(v-slot:default)
    RoundedContainer.mt-1.flex-shrink-0(v-if="cartPrefix === CartPrefix.RESTAURANT")
      div.pt-3
        h6.text-h6.pb-2 {{ $t("reservation.date") }}
        EatField(name="dateTimeSlot" v-slot="{ value, handleChange, errored }")
          DateTimeSlotButton(:reservation="true" :modelValue="value" @update:modelValue="handleChange" :errored="errored")
          EatFieldMessage(name="dateTimeSlot")
        
      div.pb-6
        h6.text-h6.pb-2 {{ $t("reservation.people") }}
        EatField(name="covers" v-slot="{ value, handleChange, errored }")
          EatNumberInput.border.rounded-xl.pa-4(:modelValue="value" @update:modelValue="handleChange" :min="1" :max="maxCovers" icon="$people" :errored="errored")

    RoundedContainer.mt-1.mb-2.flex-shrink-0(title="Info")

      EatField(name="name" v-slot="{ value, handleChange, errored }")
        EatTextInput(type="text" :modelValue="value" @update:modelValue="handleChange" placeholder="form.name.placeholder" icon="$people" :errored="errored")
        EatFieldMessage(name="name" hint="form.hint.required")

      EatField(name="email" v-slot="{ value, handleChange, errored }")
        EatTextInput(type="email" :modelValue="value" @update:modelValue="handleChange" placeholder="form.email.placeholder" icon="$mail" :errored="errored")
        EatFieldMessage(name="email" hint="form.hint.required")

      EatField(name="phone" v-slot="{ value, handleChange, errored }")
        EatTextInput(type="tel" :modelValue="value" @update:modelValue="handleChange" placeholder="form.phone.placeholder" icon="$phone" :errored="errored")
        EatFieldMessage(name="phone" hint="form.hint.required")

      EatField(name="note" v-slot="{ value, handleChange, errored }")
        EatTextArea(:modelValue="value" @update:modelValue="handleChange" :errored="errored" placeholder="form.note.placeholder" rows="2")
        EatFieldMessage(name="note")

      div(v-if="!isAuthenticated")

        EatField.pt-1(name="privacy" v-slot="{ value, handleChange }")
          EatCheckbox(:modelValue="value" @update:modelValue="handleChange") {{ $t('reservation.privacy.policyText') }}
            a.text-decoration-underline.ml-1(:href="app?.urlPrivacyPolicy" @click.stop target="_self") {{ $t('reservation.privacy.policyLink') }}
          EatFieldMessage(name="privacy" hint="form.hint.required")

        EatField.pb-6(name="g-recaptcha-response" v-slot="{ value, handleChange }")
          EatRecaptcha(:modelValue="value" @update:modelValue="handleChange")
          EatFieldMessage(name="g-recaptcha-response" hint="form.hint.required")

  template(v-slot:cta)
    EatCta                
      EatCtaButton(
        type="button"
        @click="onSubmit"
        :primary="true"
        :loading="isSubmitting"
      ) {{ $t('reservation.makeReservation') }}
</template>

<script setup lang="ts">
import { reserveAuthenticated, reserveUnauthenticated } from "@/api/Reservation";
import { getProfile } from "@/api/User";
import DateTimeSlotButton from "@/components/dateTimeSlots/DateTimeSlotButton.vue";
import { Saletype } from "@/models/App";
import { useApp } from "@/store/app";
import { setAppTitle } from "@/store/appTitle";
import { computed, onMounted, ref } from "vue";
import { DateTime } from "luxon";
import { useReservation } from "@/store/reservation";
import { CartPrefix } from "@/models/Cart";
import { useCart } from "@/store/cart";
import { useDateTimeSlots } from "@/store/timeSlots/dateTimeSlots";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { inject } from "vue";
import type { Auth } from "@/plugins/auth";
import type { Ref } from "vue";
import { useForm } from "vee-validate";
import { string, boolean, object } from "yup";

const viewLoading = ref(true);
const auth = inject('auth') as Ref<Auth>;
const isAuthenticated = auth.value.check();
const { app, reservationService, getFromToTimeSlots } = useApp();
const { cartPrefix } = useCart();
const { getInitialReserveDateTimeSlots } = useDateTimeSlots();
const i18n = useI18n();
setAppTitle(i18n.t("reservation.appTitle").toString());
const maxCovers = computed(() => {
  return reservationService.value?.maxCoversForTableReservation || 1;
});
const fromToTimeSlots = ref(false);

const validationSchema = {
  dateTimeSlot: object({
    date: object().required(() => ({ key: 'form.errors.dateTimeSlot.invalid' })),
    timeSlot: object().required(() => ({ key: 'form.errors.dateTimeSlot.invalid' }))
  }).required(),
  covers: string().required(),
  name: string().required().min(3).max(25).label('form.name.fieldName'),
  email: string().required().email().label('form.email.fieldName'),
  phone: string().required().min(3).label('form.phone.fieldName'),
  privacy: isAuthenticated ? boolean() : boolean().required()
    .oneOf([true], ({ label }) => ({ key: 'form.errors.required', params: { label }}))
    .label('form.privacy.fieldName'),
  'g-recaptcha-response': isAuthenticated ? string() : string().required(),
}

const { setFieldValue, handleSubmit, isSubmitting } = useForm({
  validationSchema
});

const { reservation } = useReservation();

const router = useRouter();
const makeReservation = async (values: any) => {

  const data = {
    covers: values.covers,
    date: values.dateTimeSlot.timeSlot.from,
    name: values.name,
    phone: values.phone,
    email: values.email,
    privacy: values.privacy,
    note: values.note,
    'g-recaptcha-response': values['g-recaptcha-response']
  };

  try {
    const response = isAuthenticated ? 
      await reserveAuthenticated(data) : 
      await reserveUnauthenticated(data);
    const date = DateTime.fromISO(values.dateTimeSlot.timeSlot.to)?.toFormat("cccc d LLLL", { locale: i18n.locale.value });
    const time = values.dateTimeSlot.timeSlot.time;
    if (date && time) reservation.value = { reservationId: response.id, date, time };
    router.push({ name: "reservation.success" });
  } catch(e) {
    setFieldValue('g-recaptcha-response', '');
    throw e;
  }
};

const onInvalidFormClick = async () => {
  setSnackbar(
    i18n.t("reservation.snackbar.checkMandatoryFields"),
    TypeSnackbar.ERROR
  );
};

const onSubmit = handleSubmit(
  makeReservation, 
  onInvalidFormClick
);

onMounted(async () => {
  if (reservationService.value?.externalUrl) {
    viewLoading.value = true;
    window.location.replace(reservationService.value.externalUrl);
  } else {
    fromToTimeSlots.value = getFromToTimeSlots(Saletype.RESERVATION);
  
    const { date, timeSlot } = await getInitialReserveDateTimeSlots(); 
    setFieldValue('dateTimeSlot', { date, timeSlot });
  
    // 2 by default, or 1 if maxCovers is 1
    setFieldValue('covers', Math.min(2, maxCovers.value));
  
    if (isAuthenticated) {
      const user = await getProfile();
      setFieldValue('name', `${user.name} ${user.surname}`);
      setFieldValue('email', user.email);
      setFieldValue('phone', user.phones?.length ? user.phones[0] : "");
    }
  
    viewLoading.value = false;
  }
});
</script>

<style lang="scss" scoped>
.covers-number__container {
  min-height: 64px;
}

.covers-stepper__btn-container {
  &.left {
    border-right: none !important;
  }

  .covers-stepper__btn {
    height: 32px;
    width: 38px;
    display: grid;
    place-content: center;
  }
}

.reserve-bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
