<template lang="pug">
EatView
  div#order-scroll-trigger.flex-grow-1.bg-neutral-a.rounded-t-xxl(v-if="hasOnlyExternalOrder" v-scrollable="true")
    LocationInfo.mb-1
    SaletypeSection.rounded-b-xxl.overflow-hidden
  div.flex-grow-1(v-else v-scrollable="true" :id="`${selectedSaletype}-order-scrollable`")
    div#order-scroll-trigger.flex-grow-1.flex-shrink-0.bg-neutral-a.rounded-t-xxl
      LocationInfo.mb-1
      SaletypeSection(style="--saletype-section-height: 480px")

    OrderInfoSummary

    Menu.bg-neutral-a(:saletype="selectedSaletype" :key="selectedSaletype" v-if="canShowMenu && selectedSaletype")

    div.bg-neutral-a.pa-5.text-center(v-if="app")
      a.text-decoration-underline.ml-1(:href="app.urlPrivacyPolicy" target="_self" @click.stop) {{ $t('order.privacyPolicyLink') }}
      span {{ $t("general.and") }}
      a.text-decoration-underline.ml-1(:href="app.urlTermsOfSales" @click.stop) {{ $t('order.termsOfSalesLink') }}

  ProductDetail
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import LocationInfo from "@/views/order/partials/LocationInfo.vue";
import SaletypeSection from "@/views/order/partials/SaletypeSection.vue";
import OrderInfoSummary from "@/views/order/partials/OrderInfoSummary.vue";
import Menu from "@/views/order/partials/Menu.vue";
import ProductDetail from "@/components/menuProduct/ProductDetail.vue";
import { useOrderSaletype } from "@/store/order/saletype";
import { Saletype } from "@/models/App";
import { useApp } from "@/store/app";
import { setAppTitle } from "@/store/appTitle";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { computed } from "vue";

const i18n = useI18n();
const router = useRouter();
setAppTitle(i18n.t("order.appTitle").toString());
const { activeSaletypes, hasOnlyExternalOrder } = useApp();
const canShowMenu = computed(() => {
  return activeSaletypes.value.includes(Saletype.DELIVERY) ||
    activeSaletypes.value.includes(Saletype.TAKEAWAY);
});

onBeforeMount(() => {
  if (!canShowMenu.value) router.push({ name: "location" });
});

const { app } = useApp();
const { selectedSaletype } = useOrderSaletype();
</script>

<style lang="scss" scoped>
.scroll-snap-container {
  scroll-snap-type: y proximity;
}
.scroll-snap {
  scroll-snap-align: start;
}

.date-time-picker__saletype {
  text-transform: capitalize;
}
.gradient-border-bottom {
  height: 2px;
  background: linear-gradient(226deg, rgba(197, 245, 49, 1) 10%, rgba(0, 113, 242, 1) 92%);
}
</style>
