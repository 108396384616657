import authRoutes from "@/routes/authRoutes";
import userRoutes from "@/routes/userRoutes";
import EatTopBar from "@/layout/partials/EatTopBar.vue";
import OrderTopBar from "@/views/order/partials/TopBar.vue";
import PaymentSuccess from "@/views/cart/PaymentSuccess.vue";
import PaymentError from "@/views/cart/PaymentError.vue";
import Cart from "@/views/cart/Cart.vue";
import Checkout from "@/views/cart/Checkout.vue";
import Location from "@/views/location/Location.vue";
import Reserve from "@/views/reserve/Reserve.vue";
import ReserveSuccess from "@/views/reserve/ReserveSuccess.vue";
import StartTakeaway from "@/apps/restaurant/views/StartTakeaway.vue";
import { type RouteRecordRaw, createRouter, createWebHashHistory, type RouteLocation } from "vue-router";
import RestaurantOrder from "@/views/order/RestaurantOrder.vue";
import MustClearCart from "@/views/order/more/MustClearCart.vue";
import DeliveryAddress from "@/views/user/address/DeliveryAddress.vue";
import NewAddress from "@/views/user/address/NewAddress.vue";
import ModifyAddress from "@/views/user/address/ModifyAddress.vue";
import CountrySelector from "@/views/user/address/CountrySelector.vue";
import CrossSellingCategories from "@/views/cart/CrossSellingCategories.vue";
import PaymentPending from "@/views/cart/PaymentPending.vue";
import FormTest from "@/views/FormTest.vue";

const reservationRoutes: RouteRecordRaw[] = [
  {
    path: "/reserve",
    name: "reservation",
    components: {
      topBar: EatTopBar,
      default: Reserve
    },
    meta: { mainView: true, topBarBorderBottom: true }
  },
  {
    path: "/reserve/success",
    name: "reservation.success",
    components: {
      default: ReserveSuccess
    },
    meta: { hideNavbar: true }
  }
];

const orderRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: { name: "order" }
  },
  /* {
    path: "/test",
    name: "test",
    components: {
      default: FormTest,
    },
    meta: { mainView: true, showAddToHomePopOver: true }
  }, */
  {
    path: "/order",
    name: "order",
    components: {
      topBar: OrderTopBar,
      default: RestaurantOrder,
      more: MustClearCart
    },
    meta: { mainView: true, showAddToHomePopOver: true }
  },
  {
    path: "/order/delivery-address",
    name: "order.deliveryAddress",
    components: {
      topBar: EatTopBar,
      default: DeliveryAddress
    },
    props: { default: { baseRoute: "order" } },
    meta: { backgroundClass: 'bg-primary', hideNavbar: true, showBackButton: true }
  },
  {
    path: "/order/new-address",
    name: "order.newAddress",
    components: {
      topBar: EatTopBar,
      default: NewAddress
    },
    props: { default: (route: RouteLocation) => ({ validate: route.query.validate, baseRoute: "order" }) },
    meta: { hideNavbar: true, hasCloseButton: true, doNotSaveToHistory: true }
  },
  {
    path: "/order/new-address/country",
    name: "order.newAddressCountry",
    components: {
      topBar: EatTopBar,
      default: CountrySelector
    },
    props: { default: { baseRoute: "order" } },
    meta: { hideNavbar: true, showBackButton: true, doNotSaveToHistory: true }
  },
  {
    path: "/order/modify-address/:index",
    name: "order.modifyAddress",
    components: {
      topBar: EatTopBar,
      default: ModifyAddress
    },
    props: { default: (route: RouteLocation) => ({ index: route.params.index, baseRoute: "order" }) },
    meta: { hideNavbar: true, hasCloseButton: true, doNotSaveToHistory: true }
  },
  {
    path: "/order/modify-address/:index/country",
    name: "order.modifyAddressCountry",
    components: {
      topBar: EatTopBar,
      default: CountrySelector
    },
    props: { default: (route: RouteLocation) => ({ index: route.params.index, baseRoute: "order" }) },
    meta: { hideNavbar: true, hasCloseButton: true, doNotSaveToHistory: true }
  }
];

const cartRoutes: RouteRecordRaw[] = [
  {
    path: "/cart",
    name: "cart",
    components: {
      topBar: EatTopBar,
      default: Cart,
      more: MustClearCart
    },
    meta: { mainView: true, showMoreButton: true, topBarBorderBottom: true }
  },
  {
    path: "/cart/cross-selling",
    name: "cart.crossSelling",
    components: {
      topBar: EatTopBar,
      default: CrossSellingCategories
    },
    meta: { showBackButton: true, topBarBorderBottom: true }
  },
  {
    path: "/cart/checkout",
    name: "cart.checkout",
    components: {
      topBar: EatTopBar,
      default: Checkout
    },
    props: { default: { authenticated: true } },
    meta: {
      auth: true,
      authenticated: true,
      showBackButton: true,
      topBarBorderBottom: true
    }
  }
];

const paymentsRoutes: RouteRecordRaw[] = [
  {
    path: "/pagamento/successo",
    name: "payment.success",
    components: {
      default: PaymentSuccess
    },
    props: { default: { authenticated: true } },
    meta: {
      hideTopBar: true,
      hideNavbar: true,
      doNotSaveToHistory: true,
    }
  },
  {
    path: "/pagamento/elaborazione",
    name: "order.pending",
    components: {
      default: PaymentPending
    },
    props: { default: { authenticated: true } },
    meta: {
      hideTopBar: true,
      hideNavbar: true,
      doNotSaveToHistory: true
    }
  },
  {
    path: "/pagamento/fallito",
    name: "payment.error",
    components: {
      default: PaymentError
    },
    props: { default: { authenticated: true } },
    meta: {
      hideNavbar: true,
      doNotSaveToHistory: true
    }
  }
];

const routes: RouteRecordRaw[] = [
  {
    path: "/location",
    name: "location",
    components: {
      default: Location
    },
    meta: { mainView: true }
  },
  ...reservationRoutes,
  ...orderRoutes,
  ...cartRoutes,
  ...paymentsRoutes,
  {
    path: "/inizia-takeaway",
    name: "startTakeaway",
    components: {
      default: StartTakeaway
    },
    meta: {
      hideNavbar: true,
    }
  },
  ...authRoutes,
  ...userRoutes,
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: "order" }
  }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
