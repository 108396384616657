<template lang="pug">
div.bg-white.flex-grow-1.d-flex.align-center
  Loading.text-primary
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import Loading from "@/components/utils/Loading.vue";
import { getQueryParam } from "@/utils/queryParams";

const priceList = getQueryParam("priceList");

onMounted(() => {
  const takeAwayOnSiteURL = priceList
    ? `/takeAwayOnSite?priceList=${priceList}/`
    : "/takeAwayOnSite";
  location.replace(takeAwayOnSiteURL);
});
</script>
