<template lang="pug">
MoreDialog
  template(v-slot:top)
    div.container.d-flex.justify-center.position-relative
      EatIconBtn.close-btn(icon="$chevronDown" :size="18" @click="onCancel")
      div.text-h6 {{ $t('order.mustClearCart.changeOrder') }}
  template(v-slot:main)
    div.container.text-center.px-4.pb-15(v-if="selectedSaletype") {{$t('order.mustClearCart.productsInCart', { saletype: $t(`general.saletype.${selectedSaletype}`)}) }}
  template(v-slot:bottom)
    EatCta
      EatCtaButton(@click="onConfirmClearCart" :primary="true") {{ $t('order.mustClearCart.emptyCart') }}
</template>

<script setup lang="ts">
import { nextTick } from "vue";
import MoreDialog from "@/layout/partials/MoreDialog.vue";
import { useShowMoreDialog } from "@/store/layout/moreDialog";
import { useOrderSaletype } from "@/store/order/saletype";
import { useCart } from "@/store/cart";
import { useCartItems } from "@/store/cart/cartItems";
import { useRoute } from "vue-router";

const showMoreDialog = useShowMoreDialog();
const { cartPrefix, clearCart, loadRestaurantCart } = useCart();
const { clearCartItems } = useCartItems();
const route = useRoute();

const { selectedSaletype, mayBeNewSaletype } = useOrderSaletype();

const onConfirmClearCart = () => {
  showMoreDialog.value = false;

  if (mayBeNewSaletype.value) {
    clearCart();
    nextTick(async () => {
      if (mayBeNewSaletype.value) {
        await loadRestaurantCart(mayBeNewSaletype.value);
        mayBeNewSaletype.value = null;
      }
    });
  } else if (route.name === "cart" && cartPrefix.value) {
    clearCartItems();
  }
};

const onCancel = () => {
  mayBeNewSaletype.value = null;
  showMoreDialog.value = false;
};
</script>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  top: 20px;
  left: 20px;
}
</style>
